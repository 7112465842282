import { Injectable } from "@angular/core";
import {
  updateCount,
  getResultMap
} from "@viewer/search-result-module/faceted-search/faceted-computation";
import { cloneDeep } from "lodash";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { Subject } from "rxjs";
import { Store } from "@viewer/core/state/store";

export enum SearchTab {
  DOC = 0,
  TASK = 1,
  PART = 2,
  IPC = 3
}

export abstract class FacetSelection {
  public abstract reset(): void;
}

export class FacetUniverseBase extends FacetSelection {
  versions: string[] = [];
  manual: string[] = [];
  chapter: string[] = [];
  section: string[] = [];
  revision: string[] = [];

  public reset(): void {
    this.versions = [];
    this.manual = [];
    this.chapter = [];
    this.section = [];
    this.revision = [];
  }
}

export class FacetUniverseDoc extends FacetUniverseBase {
  document: string[] = [];
  types: string[] = [];

  public reset(): void {
    this.document = [];
    this.types = [];
  }
}

export class FacetUniversePart extends FacetUniverseBase {
  parts: string[] = [];
  types: string[] = [];

  public reset(): void {
    this.parts = [];
    this.types = [];
  }
}

export class FacetUniverseTask extends FacetUniverseBase {
  interval: string[] = [];
  chapterATA: string[] = [];
  limitType: string[] = [];
  intervalType: string[] = [];
  inspection: string[] = [];

  public reset(): void {
    this.interval = [];
    this.chapterATA = [];
    this.limitType = [];
    this.inspection = [];
    this.intervalType = [];
  }
}

export class FacetUniverseTaskS1000D extends FacetUniverseTask {
  climat: string[] = [];

  public reset(): void {
    this.climat = [];
  }
}

export class FacetUniverveSuperseded extends FacetUniverseDoc {
  superseded: string[] = [];

  public reset(): void {
    this.superseded = [];
  }
}

@Injectable()
export class FacetService {
  // replace categoryList with selected universe
  public categoryList = [];
  public universeDoc = [
    "versions",
    "types",
    "revision",
    "manual",
    "chapter",
    "section",
    "document"
  ];
  public universeTask = [
    "versions",
    "revision",
    "inspection",
    "manual",
    "chapter",
    "section",
    "chapterATA",
    "limitType",
    "intervalType",
    "interval"
  ];

  public universePart = ["versions", "types", "revision", "chapter", "section", "parts"];

  // SPEC: Climat facet should be positioned between limitType and interval
  public universeTaskS1000D = [
    ...this.universeTask.slice(0, 8),
    "climat",
    ...this.universeTask.slice(8)
  ];

  // used in html faceted-search.component to iterate on Interval sub categories
  public intervalCategories = ["calendar", "cycle", "fh", "other"];
  public facetsIntervalCategories: { [key: string]: string[] } = {};
  // shortcut to json key (en.json, fr.json ... )
  public facetTranslateNames = {
    versions: "search.facets.versions",
    types: "search.facets.types",
    revision: "search.facets.revision",
    manual: "search.facets.manual",
    chapter: "search.facets.chapter",
    section: "search.facets.section",
    calendar: "search.facets.calendar",
    chapterATA: "search.facets.chapterATA",
    climat: "search.facets.climat",
    interval: "search.facets.interval",
    cycle: "search.facets.cycles",
    fh: "search.facets.fh",
    other: "search.facets.other",
    limitType: "search.facets.limitType",
    intervalType: "search.facets.intervalType",
    inspection: "search.facets.inspection",
    document: "search.facets.document"
  };
  // If we used a BehaviorSubject instead of of Subject we would have to
  // take care of reseting or unsubscribing when leaving the search.result page.
  public filteredIds = new Subject<number | number[]>();

  /**
   * This object contain all facets of the search with the selected universe.
   * Exemple :
   * {
   *  chapter: (34) ["00", "01", "03", ...]
   *  chapterATA: (6) ["60", "62", "63", ...]
   *  interval: (27) ["2 FH", "10 FH", ... ]
   *  limitType: (5) ["-", "CHK", "OC", "OTL", "SLL"]
   *  manual: (11) ["", "ALS", "AMM", "IPC", ...]
   *  revision: (3) ["changed", "new", "unchanged"]
   *  versions: (3) ["", "B4", "T2"]
   * }
   */

  public facetsValuesByCategory: { [key: string]: string[] } = {};
  /**
   * This object contain selected facets with category set to true or false
   * Exemple :
   * {
   *  chapter: []
   *  manual: [SRM: true]
   *  revision: [new: false]
   * }
   */
  public facetsModelsByCategory = {};

  /**
   * This object is a map that will contain facets to be be displayed as key
   * and a list of search id to be displayed in the search
   * We use the lenght of the list to count the number of elements
   * If the number of elements is 0, the facet is not displayed
   * Exemple :
   * {
   *  chapter: Map(34) {"63" => Array(12), "62" => Array(9), …}
   *  ==> 0: {"63" => Array(0)}
   *      1: {"62" => Array(1)}
   *      2: {"64" => Array(0)}
   *      3: {"67" => Array(0)}
   *  chapterATA: Map(6) {"63" => Array(10), "64" => Array(148) …}
   *  interval: Map(27) {"600 FH" => Array(54), …}
   *  limitType: Map(5) {"-" => Array(174), "SLL" => Array(28), …}
   *  manual: Map(11) {"AMM" => Array(400), "SIM" => Array(9), …}
   *  revision: Map(3) {"unchanged" => Array(54), "changed" => Array(3), …}
   *  versions: Map(3) {"B4" => Array(664), "T2" => Array(634), …}
   * }
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public facets: any;

  // data structure for universe
  private facetListUniverseDoc = new FacetUniverseDoc();
  private facetListUniverseTask = new FacetUniverseTask();
  private facetListUniversePart = new FacetUniversePart();
  private facetListUniverseTaskS1000D = new FacetUniverseTaskS1000D();
  private facetListUniverseSuperseded = new FacetUniverveSuperseded();

  constructor(private router: Router, private route: ActivatedRoute, private store: Store) {}

  public initFacetValuesAndControls(facets) {
    return new Promise((resolve, _reject) => {
      // all the facets are built
      this.facetsModelsByCategory = this.store.pubInfo.isS1000D
        ? cloneDeep(this.facetListUniverseTaskS1000D)
        : cloneDeep(this.facetListUniverseTask);

      // but only the main facets are displayed
      switch (true) {
        case RegExp("/search/task").test(this.router.url):
          // if task universe, all facets are displayed.
          // Inside search we look for this.store.pubInfo.isS1000D as this.store.isS1000D is only relevent
          // for the current DM (no current DM in search)
          this.categoryList = this.store.pubInfo.isS1000D
            ? this.universeTaskS1000D
            : this.universeTask;
          this.buildFacetsForIntervalCategories();
          break;
        case RegExp("/search/part").test(this.router.url):
          this.categoryList = this.universePart;
          break;
        default:
          this.categoryList = this.universeDoc;
          break;
      }

      this.facets = cloneDeep(facets);
      Object.keys(facets).forEach(category => {
        // init facets values for each category
        // remember facets[category] is a map
        this.facetsValuesByCategory[category] = Array.from(facets[category].keys());
      });

      this.updateFacetsState();
      this.sortFacets();

      resolve("facets initiated");
    });
  }
  public updateFacetValuesAndControls(tab: number): Promise<string> {
    return new Promise((resolve, _reject) => {
      // all the facets are built
      this.facetsModelsByCategory = this.store.pubInfo.isS1000D
        ? cloneDeep(this.facetListUniverseTaskS1000D)
        : cloneDeep(this.facetListUniverseTask);

      // but only the main facets are displayed
      switch (tab) {
        case SearchTab.TASK:
          // if task universe, all facets are displayed.
          this.categoryList = this.store.pubInfo.isS1000D
            ? this.universeTaskS1000D
            : this.universeTask;
          this.buildFacetsForIntervalCategories();
          break;
        case SearchTab.PART:
          this.categoryList = this.universePart;
          break;
        default:
          this.categoryList = this.universeDoc;
          break;
      }

      this.updateFacetsState();
      this.sortFacets();

      resolve("updateFacetValuesAndControls done");
    });
  }

  /**
   * update checkbox state checked/unchecked
   */
  updateFacetsState() {
    // Get query params
    const selectionParams = this.getQueryParams();
    Object.keys(selectionParams).forEach(category => {
      this.facetsModelsByCategory[category] = [];
      selectionParams[category].forEach(
        facetValue => (this.facetsModelsByCategory[category][facetValue] = true)
      );
    });
  }

  /**
   * Update search results
   *
   * @param as default value take this.facets, check facets comment for more informations
   * @memberof FacetService
   */
  filterSearchResults(facetedSearchResult = this.facets) {
    if (!this.facets) {
      return;
    }
    // We only want to filter on displayed facets
    // so we only get relevant facets
    const selectionParams = this.getQueryParams();
    // May be factorised
    // la liste de tous les résultats à plat
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resultMap = getResultMap(selectionParams, facetedSearchResult) as any;
    const dmcResults = updateCount(this.facets, resultMap, facetedSearchResult);
    if (!this.isSelectedParamsEmpty(selectionParams)) {
      this.filteredIds.next(dmcResults);
    } else {
      // We want to reset the filtered search results to rawResults
      this.filteredIds.next(-1);
    }
  }

  isSelectedParamsEmpty(selectionParams) {
    for (const param in selectionParams) {
      if (selectionParams[param].length > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * Build facets for Interval category (calendar / flight hour / cycle)
   */
  buildFacetsForIntervalCategories(): void {
    if (!this.facetsValuesByCategory["interval"]) {
      return;
    }

    const iterableCalendar: string[] = [];
    const iterableCycle: string[] = [];
    const iterableFh: string[] = [];
    const other: string[] = [];
    for (const facet of this.facetsValuesByCategory["interval"]) {
      const facetValue = facet.split(" ")[1];
      switch (facetValue) {
        case "FH":
          iterableFh.push(facet);
          break;
        case "Y":
        case "M":
        case "D":
          iterableCalendar.push(facet);
          break;
        default:
          if (facet === "None" || facet === "Undefined") {
            other.push(facet);
          } else {
            iterableCycle.push(facet);
          }
          break;
      }
    }
    this.facetsIntervalCategories["calendar"] = iterableCalendar;
    this.facetsIntervalCategories["fh"] = iterableFh;
    this.facetsIntervalCategories["cycle"] = iterableCycle;
    this.facetsIntervalCategories["other"] = other;
  }

  /**
   * Sort Facet interval by Alphanimeric number and by increasing number
   * x is current iteration and y is next iteration
   * They are splited with a space and the first part is an alphanumeric name and the second is a number
   */
  sortIntervalFacet() {
    for (const cat of Object.keys(this.facetsIntervalCategories)) {
      this.facetsIntervalCategories[cat] = this.facetsIntervalCategories[cat].sort((x, y) => {
        if (x.split(" ")[1] === y.split(" ")[1]) {
          return parseInt(x.split(" ")[0], 10) - parseInt(y.split(" ")[0], 10);
        } else {
          return x.split(" ")[1] < y.split(" ")[1] ? -1 : 1;
        }
      });
    }
  }

  /**
   * The facets are sorted by Alphanimeric number and by increasing number
   */
  sortFacets() {
    for (const cat in this.facetsValuesByCategory) {
      // x is current iteration and y is next iteration
      // We are processing Interval categories differently due to the categories's names
      if (cat === "interval") {
        this.sortIntervalFacet();
      } else {
        this.facetsValuesByCategory[cat] = this.facetsValuesByCategory[cat].sort((x, y) => {
          if (parseInt(x.split(" ")[0], 10)) {
            const valx = parseInt(x.split(" ")[0], 10);
            const valy = parseInt(y.split(" ")[0], 10);
            return valx - valy;
          } else {
            return x < y ? -1 : 1;
          }
        });
      }
    }
  }

  /**
   * Update query params with selected facets
   *
   * @param fieldChange
   * @memberof FacetService
   */
  updateQueryParams(navigate = true): Promise<boolean | NavigationExtras> {
    const queryParams = {};
    Object.keys(this.facetsModelsByCategory).forEach(category => {
      const paramArray = Object.keys(this.facetsModelsByCategory[category]).filter(
        facetValue => this.facetsModelsByCategory[category][facetValue]
      );

      if (paramArray.length > 0) {
        queryParams[category] = JSON.stringify(paramArray);
      }
    });

    if (!navigate) {
      return Promise.resolve({
        queryParams,
        relativeTo: this.route
      } as NavigationExtras);
    }
    return this.router.navigate([], {
      queryParams,
      relativeTo: this.route
    });
  }

  /**
   * @memberof FacetService
   * @param category category of the facet ("manual", "chapter", "revision", ...)
   * @param facet facet to be updated
   * @param value {boolean}
   */
  updateFacetValue(category: string, facet: string, value: boolean) {
    this.facetsModelsByCategory[category][facet] = value;
    this.updateQueryParams();
  }

  /**
   * Add method to reset facets
   */
  resetFacets(): Promise<boolean | NavigationExtras> {
    this.categoryList = [];
    this.facetsIntervalCategories = {};
    this.facetListUniverseDoc.reset();
    this.facetListUniversePart.reset();
    this.facetListUniverseTask.reset();
    this.facetListUniverseTaskS1000D.reset();

    this.facetsModelsByCategory = this.store.pubInfo.isS1000D
      ? cloneDeep(this.facetListUniverseTaskS1000D)
      : cloneDeep(this.facetListUniverseTask);

    return this.updateQueryParams(false);
  }

  /**
   * Get query params (manual, chapter, ...) and return new facetsParams
   * If filtered is true, then only [revision, manual, chapter] are retrieved only if
   * the selected universe is DOC, otherwise all the facets are retrieved
   *
   * @param fitered
   * @returns
   * @memberof FacetService
   */
  private getQueryParams(): FacetSelection {
    let facetsParams: FacetSelection;
    let facetCategories: FacetSelection;
    if (this.router.url.match("/search/documents")) {
      facetsParams = new FacetUniverseDoc();
      facetCategories = this.facetListUniverseDoc;
    } else if (this.router.url.match("/search/ipc")) {
      facetsParams = new FacetUniverveSuperseded();
      facetCategories = this.facetListUniverseSuperseded;
    } else if (this.router.url.match("/search/part")) {
      facetsParams = new FacetUniversePart();
      facetCategories = this.facetListUniversePart;
    } else if (this.store.pubInfo.isS1000D) {
      facetsParams = new FacetUniverseTaskS1000D();
      facetCategories = this.facetListUniverseTaskS1000D;
    } else {
      facetsParams = new FacetUniverseTask();
      facetCategories = this.facetListUniverseTask;
    }

    Object.keys(facetCategories).forEach(category => {
      const catParam = this.route.snapshot.queryParamMap.get(category);
      facetsParams[category] = catParam ? JSON.parse(catParam) : [];
    });
    return facetsParams;
  }
}
